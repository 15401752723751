@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Qwitcher+Grypen:wght@400;700&display=swap');
@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";

body {
  margin: 0;
  font-family: 'Qwitcher Grypen', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.diff-font {
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: 'Montserrat', sans-serif;
  font-family: 'Qwitcher Grypen', cursive;
}

h1 {
  font-size: 48px;
}
